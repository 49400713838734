import React, {useRef} from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css'

import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';

import './demo.scss'

import Title from "~c/ui/front/title/title";
import Form from '~c/ui/front/form/form/form'
import Input from '~c/ui/front/form/input/input'
import TextArea from '~c/ui/front/form/textarea/textarea'
import Reference from '~c/ui/front/reference/reference'
import Button from '~c/ui/front/button/button'

import bg from '~a/images/demo/bg.webp'

export interface IRequestForm {
  namr: string
  email: string
  message: string
}

const Demo = () => {
  const initialValues = {
    name: '',
    email: '',
    message: ''
  }

  const onSubmit = (values:FormikValues) => {
    console.log(values);
  }

	const ref = useRef<HTMLHeadingElement>(null)

  const validationSchema = Yup.object().shape<IRequestForm>({
    name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .min(2,'Name should be longer that 2 characters' )
      .max(20, 'Name should be shorter that 20 characters')
      .required('Field is required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Field is required'),
    message: Yup.string()
      .max(500, 'Message should be shorter than 500 characters')
  })

  const {handleSubmit, handleChange, values, touched, errors, handleBlur} = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })
  
  return (
    <section 
    id="demo"
    className="index-demo"
		ref={ref} 
    >
      <div className="index-demo__image" style={{backgroundImage:`url(${bg})`}}>
      </div>
      <div className="view-content view-content--small">
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
						<div className="index-demo__label text-purple">
							Request demo
						</div>
						<Title as="h2" className="text-white title">
							To schedule a product demo with us, please fill in your contact details
						</Title>
					</AnimationOnScroll>
          <div className="index-demo__form">
            <Form onSubmit={handleSubmit}>
              <div className="form-group form-group--top">
                <div className="form-control">
                  <Input 
                    onChange={handleChange} 
                    onBlur={handleBlur}
                    value={values.name} placeholder="Name" 
                    name="name" 
                  />

                  {touched.name && errors.name ? 
                  (<div className="form-error">{ errors.name }</div>)
                  : null}
                </div>

                <div className="form-control">
                  <Input 
                    onChange={handleChange}
                    onBlur={handleBlur} 
                    value={values.email}
                    type="email" 
                    placeholder="Email" 
                    name="email" 
                  />

                  {touched.email && errors.email ? 
                  (<div className="form-error">{ errors.email }</div>)
                  : null}
                </div>
              </div>

              <div className="form-control">
                <TextArea 
                  placeholder="Type your message here..." 
                  onChange={handleChange} name="message"
                >
                </TextArea>
                {touched.message && errors.message ? 
                (<div className="form-error">{ errors.message }</div>)
                : null}
              </div>
							<input type="hidden" name="subject" value="Request demo" />
              <Button
              as="button"
              type="submit"  
              className="button button--purple button--big"
              style={{display: 'block', margin: '24px auto 0'}}
              >
                  Submit
              </Button>
            </Form>
          </div>
          <div className="index-demo__contacts">
            <div className="index-demo__contacts-item">
              <Reference href="mailto:info@mysite.com">
                info@mysite.com
              </Reference>
            </div>
            <div className="index-demo__contacts-item">
              <Reference href="tel:123-456-7890">
                123-456-7890
              </Reference>
            </div>
            <div className="index-demo__contacts-item">
            <Reference href="https://goo.gl/maps/ipPGPokbwyR1Na358" target="_blank">
              500 Terry Francois Street<br />
              San Francisco, CA 94158
            </Reference>
            </div>
          </div>
      </div>
    </section>
  )
}

export default Demo
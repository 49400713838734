import React, { ReactNode } from 'react'

import './text.scss'

interface IText {
  children: ReactNode,
  className?: string
}

const ViewText = ({children, className}:IText) => {
  return (
    <div className={`view-text ${typeof className === 'undefined' ? '' : className}`}>{ children }</div>
  )
}

const Text = ({children, className}:IText) => {
  return (
    <p className={className}>{ children }</p>
  )
}



export {ViewText, Text}
import React, {ReactNode, ElementType} from 'react'

import './button.scss'

interface IButton {
  children: ReactNode
  as: ElementType
  type?: string
  href?: string
  className?: string
  [propName: string]: any;
}

interface IButtonAttr { type?: string; href?: string; }

const Button = ({
  children, 
  as: Tag, 
  type,
  href,
  className,
  ...rest
}:IButton) => {
  const buttonAttr:IButtonAttr = {}

  if (type) {
    buttonAttr['type' as keyof IButtonAttr] = type
  } else if (href) {
    buttonAttr['href'] = href
  }
  
  return (
    <Tag {...buttonAttr} className={className} {...rest}>{ children }</Tag>
  )
}

export default Button
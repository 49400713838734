import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css'

import "swiper/scss";

import './partners.scss'

import Title from '~c/ui/front/title/title'

import p1 from '~a/images/partners/1.webp'
import p2 from '~a/images/partners/2.webp'
import p3 from '~a/images/partners/3.webp'
import p4 from '~a/images/partners/4.webp'
import p5 from '~a/images/partners/5.webp'

const Partners = () => {
  
  return (		
			<section className="index-partners">
				<AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft" duration={2}>	
					<div className="view-content">
					<Title 
						as="h2" 
						className="text-navy title title--thin"
					>Our Partners
					</Title>
					<Swiper className="partners" slidesPerView={5} breakpoints={
							{
							320: {
								slidesPerView: 1
							},
							575: {
								slidesPerView: 2
							},
							767: {
								slidesPerView: 3
							},
							991: {
								slidesPerView: 5
							}
						}
					}>
							<SwiperSlide>
								<div className="partners__item">
									<img src={p1} alt="" />
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partners__item">
									<img src={p2} alt="" />
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partners__item">
									<img src={p3} alt="" />
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partners__item">
									<img src={p4} alt="" />
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="partners__item">
									<img src={p5} alt="" />
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</AnimationOnScroll>
			</section>
  )
}

export default Partners
import React, {ReactNode} from 'react'

import Reference from '~c/ui/front/reference/reference'

import './social-icon.scss'

interface ISocialIcon {
  children: ReactNode
  className?: string
  href: string
}

const SocialIcon = ({children, className, href}:ISocialIcon) => {
  return (
    <Reference href={href} className={`social-icon ${typeof className !== 'undefined' ? className : ''}`} target="_blank" rel="noreferrer">
      { children }
    </Reference>
  )
}

export default SocialIcon
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css'

import './promo.scss'

const promo = () => {
	return (
		<div className="index-promo" id="about">
			<AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn" duration={2}>
				<div className="view-content view-content--medium">
					<div className="index-promo__title">
					The world&apos;s <br/> <strong>first digital asset</strong> <br/> representing TRUE and VERIFIED <strong> <br/>real-world asset</strong>
					</div>
					<div className="index-promo__asset">
					Your Asset
					</div>
					<div className="index-promo__subtitle">
						Our <strong>Mission</strong> is to bridge the gap between the Digital World and the Real World by providing the necessary legal and technological framework for owners. 
					</div>
				</div>
			</AnimationOnScroll>
		</div>
	)
}

export default promo
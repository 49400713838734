import * as React from 'react';

import SocialIcon from '~c/ui/front/social-icon/social-icon'

import './footer.scss'

import fb from '~a/images/footer/fb.webp'
import tw from '~a/images/footer/tw.webp'
import ins from '~a/images/footer/ins.webp'
import ln from '~a/images/footer/ln.webp'

const Footer = () => {
  return (
  <footer className="footer">
    <div className="footer__socials">
    <SocialIcon href="https://www.google.com">
      <img src={fb} alt="" />
    </SocialIcon>
    <SocialIcon href="https://www.google.com">
      <img src={tw} alt="" />
    </SocialIcon>
    <SocialIcon href="https://www.google.com">
      <img src={ins} alt="" />
    </SocialIcon>
    <SocialIcon href="https://www.google.com">
      <img src={ln} alt="" />
    </SocialIcon>
    </div>
    <div className="footer__copyright">
      © 2022 all rights reserved by Metaverse
    </div>
  </footer>
  )
};

export default Footer

import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css'

import Title from '~c/ui/front/title/title'
import Subtitle from '~c/ui/front/subtitle/subtitle'

import './join.scss'

const Hero = () => {
  return (        
    <section className="index-join">
      <div className='view-content'>
			<AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={2}>
				<Title as="h2" className="title">
					Join <strong>METAVERSE</strong> today <br /> added <strong>value</strong> to your property
        </Title>
				<Subtitle className="subtitle subtitle--medium" id="how-it-works">
					BUILD ON ETHEREUM BLOCKCHAIN
				</Subtitle>
			</AnimationOnScroll>
    </div>
    </section>
  )
}

export default Hero
import React, {ChangeEvent} from 'react'

import './input.scss'

interface IInput {
  label?: string
  className?: string
  type?: string
  name?: string
  value?: string
  placeholder?: string
  onChange: { (e: ChangeEvent<any>): void}
  [propName: string]: any;
}

const Input = ({
  label, 
  className, 
  type = 'text', 
  name, 
  value,  
  placeholder, 
  ...rest
}:IInput) => {
  return (
    <>
      {label ? <label htmlFor={name}>{label}</label> : ''}
      <input 
      type={type} 
      id={name} 
      className={className} 
      name={name} 
      value={value} 
      placeholder={placeholder} 
      {...rest} 
      />
    </>
  )
}

export default Input
import * as React from 'react';

import Layout from '~/layouts/main';

import Hero from './sections/hero/hero'
import Documentation from './sections/join/join'
import HowItWorks from './sections/how-it-works/how-it-works'
import Partners from './sections/partners/partners'
import Technology from './sections/technology/technology'
import Promo from './sections/promo/promo'
import Demo from './sections/demo/demo'

import './index.scss'

const HomePage = (): JSX.Element => {
  
  return (
    <Layout>
      <div className="index-page">
        <Hero />
        <Documentation />
        <HowItWorks />
        <Partners />
        <Technology />
        <Promo />
        <Demo />
      </div>
    </Layout>
  );
};

export default HomePage
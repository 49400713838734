import React, {ReactNode} from 'react'

import './form.scss'

interface IForm {
  children: ReactNode,
  action?: string
  method?: string
  id?: string
  className?: string
  name?: string
  onSubmit: () => any
  [propName: string]: any;
}

const Form = ({
  children,
  action,
  method = 'POST',
  id,  
  className,
  name,
  ...rest
}:IForm) => {
  return (
    <form 
    action={action} 
    method={method} 
    id={id} 
    className={`form ${typeof className !== 'undefined' ? className: ''}`}
    name={name}
    {...rest} 
    >
      {children}
    </form>
  )
}


export default Form
import React from 'react'

import './technology-card.scss'

interface ITechnologyCard {
	graphic: string,
	title: string,
	description: string,
}

const TechnologyCard = ({graphic, title, description}:ITechnologyCard) => {
	return (
		<div className="technology-card">
			<div className="technology-card__graphic">
				<img src={ graphic } alt="" />
			</div>
			<div className="technology-card__title">
				{ title }
			</div>
			<div className="technology-card__description">
				<div className="view-text">
					<p>
						{ description }
					</p>
				</div>
			</div>
		</div>
	)
}

export default TechnologyCard;
import * as React from 'react';

import Footer from '~c/partials/footer/footer';
import Header from '~c/partials/header/header'

import './main.scss';

type Props = {
  children: JSX.Element,
};

const MainLayout = ({ children }:Props) => {
  return (
		<div className="view-wrapper">
			<div className="view-main">
				<Header />
				<main>{children}</main>
			</div>
			<Footer />
		</div>
  );
}

export default MainLayout
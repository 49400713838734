import React, { ElementType, ReactNode } from 'react'

import './title.scss'

interface ITitle {
  children: ReactNode
  as: ElementType
  className?: string
  [propName: string]: any;
}

const Title = ({children, as: Tag, className, ...rest}:ITitle) => {
  return (
    <Tag className={className} {...rest}>{ children }</Tag>
  )
}

export default Title
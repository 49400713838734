import React, {useState, useCallback, useMemo} from 'react'

import Reference from '~c/ui/front/reference/reference'
import BurgerButton from '~c/ui/front/burger-button/burger-button'

import './header.scss';

const Header = () => {
	const [isVisibleMenu, setIsVisibleMenu] = useState<boolean>(false)

	const toggleMenu = useCallback(() => setIsVisibleMenu (prevMenuState => !prevMenuState), [])

	const isMenuVisible = useMemo(() => isVisibleMenu, [])
	

  return (
		<header className="header">
			<div className="header__logo">
				<span>Truhome.io</span>
				<svg preserveAspectRatio="xMidYMid meet" data-bbox="5.137 5.637 188.725 188.725" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="5.137 5.637 188.725 188.725" role="presentation" aria-hidden="true"><g><path className="st0" d="M149.8,80l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3s-21.9-8.3-30.3,0l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3
				S141.4,88.3,149.8,80z"></path><path className="st0" d="M49.2,120l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3s21.9,8.3,30.3,0l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3
				S57.6,111.7,49.2,120z"></path><path className="st0" d="M149.8,120c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3l37.8,37.8c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3
				L149.8,120z"></path><path className="st0" d="M41.7,12c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3L49.2,80c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3L41.7,12
				z"></path></g></svg>
			</div>
			<nav className={`header__nav ${isVisibleMenu ? 'visible' : ''}`}>
					<ul>
						<li>
							<Reference href="#home" onClick={toggleMenu}>Home</Reference>
						</li>
						<li>
							<Reference href="#how-it-works" onClick={toggleMenu}>How it works</Reference>
						</li>
						<li>
							<Reference href="#about" onClick={toggleMenu}>About</Reference>
						</li>
						<li>
							<Reference href="#demo" onClick={toggleMenu}>Request demo</Reference>
						</li>
					</ul>
			</nav>
			<div className="header__burger">
				<BurgerButton showMenu={setIsVisibleMenu} isVisibleMenu={isMenuVisible} />
			</div>
		</header>
	)
};

export default Header

import React from 'react'
import HomePage from '~/containers/front/index'

interface IRoute {
  name: string,
  url: string,
  element: JSX.Element,
}


const routes:IRoute[] = [
  {
    name: 'home',
    url: '/',
    element: <HomePage />
  },
];

// routes name list routesMap = {name: url}
interface IRoutesMap {
  [key: string]: string,
}

const routesMap: IRoutesMap = {};
routes.forEach((route) => {
  if (Object.prototype.hasOwnProperty.call(route, 'name')) {
    routesMap[route.name] = route.url;
  }
});

export default routes;
export { routesMap };

import React, {ReactNode} from 'react'

import './subtitle.scss'

interface ISubtitle {
  children: ReactNode,
  className?: string
  [propName: string]: any;
}

const Title = ({children, className, ...rest}:ISubtitle) => {
  return (
    <div className={className} {...rest}>{ children }</div>
  )
}

export default Title
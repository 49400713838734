import React, {ReactNode, ChangeEvent} from 'react'

import './textarea.scss'

interface ITextArea {
  children?: ReactNode
  label?: string
  className?: string
  name?: string
  placeholder?: string
  onChange: { (e: ChangeEvent<any>): void}
  [propName: string]: any;
}

const TextArea = ({
  children,
  label,
  className, 
  name,  
  placeholder,
  ...rest 
}:ITextArea) => {
  return (
    <>
      {label ? <label htmlFor={name}>{label}</label> : ''}
      <textarea
      id={name}  
      className={className} 
      name={name} 
      placeholder={placeholder} 
      {...rest}
      >
        {children}
      </textarea>
    </>
  )
}

export default TextArea
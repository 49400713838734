import React, {ReactNode} from 'react'

import './reference.scss'

interface IReference {
  children: ReactNode
  href: string
  className?: string
  target?: string
  rel?: string
  onClick?: () => unknown
}

const Reference = React.memo(({
  children,
  href,
  className,
  target,
  rel,
  onClick 
}:IReference) => {

  return (
    <>
      <a
      href={href}  
      className={`reference ${typeof className !== 'undefined' ? className : ''}`}
      target={target}
      rel={rel} 
      onClick={onClick} 
      >
        {children}
      </a>
    </>
  )
})

Reference.displayName = 'Reference';

export default Reference
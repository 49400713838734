import React from 'react'
import 'animate.css'

import Title from '~c/ui/front/title/title'
import {ViewText, Text} from '~c/ui/front/text/text'

import './how-it-works.scss'

import image1  from '~a/images/how-it-works/1.png'
import image2  from '~a/images/how-it-works/2.png'
import image3  from '~a/images/how-it-works/3.png'
import image4  from '~a/images/how-it-works/4.png'
import image5  from '~a/images/how-it-works/5.png'

const HowItWorks = () => {
  return (
    <section className="index-how-it-works">
          <Title as="h2" className="title"> How it works </Title>
          <div className="index-how-it-works__block-wrapper">
              <div className="view-content">
                <div className="index-how-it-works__block">
                  <div className="index-how-it-works__block-item">
										<div className="index-how-it-works__block-item-content">
											<Title as="h3" className="title"> We collect all documentation to verify the legal ownership </Title>
											<ViewText>
												<Text>
													Our legal counsel and one of the largest US title company will verify all legal documentation.
												</Text>
											</ViewText>
										</div>
                  </div>
                  <div className="index-how-it-works__block-item">
                    <img src={image1} alt="" />
                  </div>
                </div>
              </div>
          </div>
           <div className="index-how-it-works__block-wrapper">
            <div className="view-content">
              <div className="index-how-it-works__block">
                <div className="index-how-it-works__block-item">
									<div className="index-how-it-works__block-item-content">
											<Title as="h3" className="title"> We Build NFT Smart Contract on Ethereum Blockchain </Title>
											<ViewText>
												<Text>
													We build customized smart contracts on ethereum blockchain tailored to your property.
												</Text>
											</ViewText>
                  </div>
                </div>
                <div className="index-how-it-works__block-item">
								<img src={image2} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="index-how-it-works__block-wrapper">
            <div className="view-content">
              <div className="index-how-it-works__block">
                <div className="index-how-it-works__block-item">
                  <div className="index-how-it-works__block-item-content">
										<Title as="h3" className="title"> We record your digital rights to your property </Title>
										<ViewText>
											<Text>
												We record your ethereum smart contract to your property by recording it on the deed. This is your smart contract and no one else can replicate this.
												</Text>
										</ViewText>
                  </div>
                </div>
                <div className="index-how-it-works__block-item">
									<img src={image3} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="index-how-it-works__block-wrapper">
            <div className="view-content">
              <div className="index-how-it-works__block">
                <div className="index-how-it-works__block-item">
										<div className="index-how-it-works__block-item-content">
											<Title as="h3" className="title"> Our Legal Counsel and Title Agency validate the recording </Title>
											<ViewText>
												<Text>
													Final step - Dot the i&apos;s and Cross the t&apos;s Let&apos;s make sure we didn&apos;t miss anything!
												</Text>
											</ViewText>
                    </div>
                </div>
                <div className="index-how-it-works__block-item">
									<img src={image4} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="index-how-it-works__block-wrapper">
            <div className="view-content">
              <div className="index-how-it-works__block">
                <div className="index-how-it-works__block-item">
                    <div className="index-how-it-works__block-item-content">
											<Title as="h3" className="title"> Congratulations! 											<svg preserveAspectRatio="xMidYMid meet" data-bbox="27.5 24.5 145 151" viewBox="27.5 24.5 145 151" height="40" width="40" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true">
														<g>
																<path d="M166.82 92.908c-4.907-6.392-12.402-9.912-21.097-9.912h-15.146c6.23-13.612 6.442-33.453 1.485-45.221-3.609-8.563-9.786-13.275-17.398-13.275-2.065 0-4.226.362-6.425 1.067C97.378 29.064 96.97 37.703 96.61 45.321c-.525 11.074-1.32 27.359-29.535 48.386V83.144H27.5v90.882h39.575v-11.241c6.772 6.125 16.413 12.715 26.557 12.715h45.764c9.546 0 18.961-7.209 21.44-16.41l10.278-38.174c2.838-10.55 1.275-20.76-4.294-28.008zM56.546 163.515H38.029V93.66h18.518l-.001 69.855zm104.396-45.33l-10.278 38.176c-1.237 4.598-6.501 8.625-11.269 8.625H93.632c-9.948 0-21.633-11.478-26.557-17.341v-41.027c38.409-26.049 39.433-47.737 40.053-60.801.38-8.062.829-9.112 4.341-10.246 1.153-.371 2.23-.557 3.196-.557 3.193 0 5.778 2.302 7.69 6.838 5.25 12.462 2.515 34.824-5.228 42.724l-8.754 8.931h37.35c5.424 0 9.831 2.003 12.74 5.796 3.559 4.637 4.463 11.515 2.479 18.882z" fill="#4854C7" data-color="1"></path>
														</g>
												</svg> <br /> You own your <br /> own Digital Asset. <br /> Welcome to Metaverse! 
											</Title>
                    </div>
                </div>
                <div className="index-how-it-works__block-item">
									<img src={image5} alt="" />
                </div>
              </div>
            </div>
          </div>
    </section>
  )
}

export default HowItWorks
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css'

import "swiper/scss";

import TechnologyCard from '~c/ui/front/cards/technology-card'
import './technology.scss'

import Title from '~c/ui/front/title/title'

import fast from '~a/images/technology/fast.svg'
import secure from '~a/images/technology/secure.svg'
import verified from '~a/images/technology/verified.svg'

const Technology = () => {
  const data = [
		{
			id: 1,
			graphic: fast,
			title: 'Fast',
			description: `Transactions are faster on the Ethereum network than on Bitcoin's. 

			In Addition we are working on implementing Solana and Cardano to offer options.`,
		},
		{
			id: 2,
			graphic: secure,
			title: 'Secure',
			description: `It's all about security and customer safety. We work with the latest technologies to implement up to date security.`,
		},
		{
			id: 3,
			graphic: verified,
			title: 'Verified',
			description: `What's the point of having crypto if you can't verify the true ownerhips?
			That's where we come in.`,
		},
	]
  return (
		<section className="index-technology">
			<AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" duration={2}>

					<div className="view-content">
					<Title 
						as="h2" 
						className="text-navy title title--thin"
					>Our Technology
					</Title>
					<Swiper className="technologies" slidesPerView={3} breakpoints={
							{
							320: {
								slidesPerView: 1
							},
							575: {
								slidesPerView: 2
							},
							991: {
								slidesPerView: 3
							}
						}
					}>
							{ data.map(({id, graphic, title, description}) => {
								return (
									<SwiperSlide key={id}>
										<TechnologyCard graphic={graphic} title = {title} description={description} />
									</SwiperSlide>
								)
							}) }
						</Swiper>
					</div>
			</AnimationOnScroll>
		</section>
  )
}

export default Technology
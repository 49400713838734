import React from 'react'

import Title from '~c/ui/front/title/title'
import Subtitle from '~c/ui/front/subtitle/subtitle'

import './hero.scss'
import image from '~a/images/hero/image.webp'


const Hero = () => {

  return (        
    <section className="index-hero" id="home">
      <div className='view-content'>
        <div className="index-hero__container">
          <div className="index-hero__left">
              <Title 
                as="h1" 
                className="text-navy title title--thin title--index-hero"
              >
                We create<br/>
                <strong>
                  Next generation
                </strong>
                <br/>value for your property
              </Title>
          </div>
        <div className="index-hero__right">
					<img src={image} alt="" />
				</div>
      </div>
			<Subtitle 
				className="text-navy subtitle subtitle--large subtitle--index-hero" >
				The most trusted source for building <strong>your property value</strong> <br/>with <strong>blockchain</strong> technology
			</Subtitle>
    </div>
    </section>
  )
}

export default Hero


import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from '~/routes';


const App = () => {
  // routes loop
  const routesComponents = routes.map((route) => {
    return (
      <Route
        path={route.url}
        element={route.element}
        key={route.url}
      />
    );
  });
  
  return (
	<Router>
			<Routes>
				{routesComponents}
			</Routes>
	</Router>
);
}

export default App


